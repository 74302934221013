// slick
$(function() {
	$('.visual__slider').slick({
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		easing: 'linear',
		dots: true,
		lazyLoad: 'ondemand',
		pauseOnFocus: false,
		pauseOnHover: false,
		speed: 3000,
		fade: true,
	});
	
    $('.topic__list').slick({
        infinite: true,
        dots: true,
		arrows: false,
        centerMode: true,
        centerPadding: '10%',
        autoplay: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
		{
		  breakpoint: 1024,
		  settings: {
			infinite: true,
        	dots: true,
			arrows: false,
			centerMode: true,
			centerPadding: '10%',
			autoplay: true,
			slidesToShow: 3,
			slidesToScroll: 1,
		  }
		},
		{
		  breakpoint: 767.499,
		  settings: {
			infinite: true,
        	dots: true,
			arrows: false,
			centerMode: true,
			centerPadding: '10%',
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		  }
		}
		]
    });
});

$(".topic__list__item__box__title").each(function(){
  var size = 22;
  var txt = $(this).text();
  var suffix = '…';
  var b = 0;
  for(var i = 0; i < txt.length; i++) {
    b += txt.charCodeAt(i) <= 255 ? 0.5 : 1;
    if (b > size) {
      txt = txt.substr(0, i) + suffix;
      break;
    }
  }
  $(this).text(txt);
});